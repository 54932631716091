import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import Logo from '../images/NadaZlajaLogo.png';
import { BsCursor } from 'react-icons/bs';
import { GiSmartphone } from 'react-icons/gi';

import { Navbar, Nav, Container, Col } from "react-bootstrap";

const Navigation = styled(Navbar)`
  background-repeat: repeat-x;
  height: 100px;
  margin-bottom: 0px;
`;

const NavContainer = styled(Container)`
  position: absolute;
  background: #FABC3D;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  top: 80px;
  left: 0;
  right: 0;
  text-align: center;
`;

const LowerNav = styled(Nav)`
  width: 100%;
  justify-content: space-around;
`;

const CustomNavbar = ({ pageInfo, title }) => {
  return (
    <>
      <Navigation variant="dark" expand="lg" id="site-navbar" className="h5 meni">
        <Container>
          <LowerNav>
            <Col sx={12} md={4}>
              <Link to="/" className="link-no-style">
                <img height="50px" src={Logo} alt="Logo" />
                <Navbar.Brand as="span">{title}</Navbar.Brand>
              </Link>
            </Col>
            <Col sx={12} md={4} className="only-desktop">
              <BsCursor className="orange-icon" height="50px" />
              <Navbar.Brand as="span" className="icon-text">Izvorska 48, 11000 Beograd</Navbar.Brand>
            </Col>
            <Col sx={12} md={4} className="only-desktop">
              <GiSmartphone className="orange-icon" height="50px" />
              <Navbar.Brand as="span" className="icon-text">+381 63 252-069</Navbar.Brand>
            </Col>
          </LowerNav>

        </Container>
        <NavContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
          <Navbar.Collapse id="basic-navbar-nav mr-auto">
            <LowerNav className="ml-auto" fill>
              <Link to="/objekti" activeClassName="active" className="link-no-style">
                <Nav.Link as="span" eventKey="objekti" className="underline">
                  Objekti
                </Nav.Link>
              </Link>
              <Link to="/slike" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="slike" className="underline">
                  Slike
                </Nav.Link>
              </Link>
              <Link to="/radovi" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="radovi" className="underline">
                  Radovi u kamenu
                </Nav.Link>
              </Link>
              <Link to="/biografija" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="biografija" className="underline">
                  Biografija
                </Nav.Link>
              </Link>
              <Link to="/licence" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="licence" className="underline">
                  Licence
                </Nav.Link>
              </Link>
              <Link to="/nagrade" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="nagrade" className="underline">
                  Nagrade
                </Nav.Link>
              </Link>
            </LowerNav>
          </Navbar.Collapse>
        </NavContainer>
      </Navigation>
    </>
  )
}

export default CustomNavbar;
