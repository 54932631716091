import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';

import { Container, Row, Col, Navbar } from "react-bootstrap";
import Logo from '../images/NadaZlajaLogo.png';
import { BsCursor } from 'react-icons/bs';
import { GiSmartphone } from 'react-icons/gi';
import { FaGlobeEurope } from 'react-icons/fa';

const FooterContainer = styled(Container)`
  color: white;
`;

const Footer = ({ title }) => {
  return (
    <FooterContainer fluid className="no-p-m">
      <Container fluid className="footer-container no-p-m text-center py-5">
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <Link to="/" className="link-no-style">
                <img height="50px" src={Logo} alt="Logo" />
                <Navbar.Brand as="span">{title}</Navbar.Brand>
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <Col xs={6} className="d-flex flex-column align-items-start">
                <Link to="/objekti" className="text-white link-no-style">Objekti</Link>
                <Link to="/slike" className="text-white link-no-style">Slike</Link>
                <Link to="/radovi" className="text-white link-no-style">Radovi u kamenu</Link>
              </Col>
              <Col xs={6} className="d-flex flex-column align-items-start">
                <Link to="/biografija" className="text-white link-no-style">Biografija</Link>
                <Link to="/licence" className="text-white link-no-style">Licence</Link>
                <Link to="/nagrade" className="text-white link-no-style">Nagrade</Link>
              </Col>
            </Col>
            <Col xs={12} md={4} className="d-flex flex-column align-items-start">
              <div>
                <BsCursor className="orange-icon" height="50px" />
                <Navbar.Brand as="span" className="icon-text">Izvorska 48, 11000 Beograd</Navbar.Brand>
              </div>
              <div>
                <GiSmartphone className="orange-icon" height="50px" />
                <Navbar.Brand as="span" className="icon-text">+381 63 252-069</Navbar.Brand>
              </div>
              <div>
                <FaGlobeEurope className="orange-icon" height="50px" />
                <Navbar.Brand as="span" className="icon-text">
                  https://nzbeograd.com
                </Navbar.Brand>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="px-0 mt-5">
        <Row noGutters>
          <Col className="footer-col">
            <footer>
              <span>
                Copyright © NZ Arh & Art Studio {new Date().getFullYear()} | Sva prava zadržana | handcrafted by
                {` `}
                <a href="https://manoilov.me"> nmanoilov</a>
              </span>
            </footer>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  )
}

export default Footer;
